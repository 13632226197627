<template>
  <div class="Intro">
    <div
      class="IntroInfo"
      style="line-height: 20px;"
    >{{courseInfo.course_desc}}</div>
    <!-- <p>关于升职加薪，你是不是也遇到这些问题</p>
    <div class="problems">
      <div class="problem" v-for="(item, index) in problemList" :key="index">
        <img :src="item.img" alt="" />
        <p>{{ item.title }}</p>
        <p>{{ item.content }}</p>
      </div>
    </div>
    <p>面对新的挑战，课程已升级，助你提升硬实力</p>
    <div class="upgrades">
      <div class="upgrade" v-for="(item, index) in upgradeList" :key="index">
        <img :src="item.img" alt="" />
        <p>{{ item.title }}</p>
        <p>{{ item.content }}</p>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  props: ["courseInfo"],

  data() {
    return {
      problemList: [
        {
          img: require("../../assets/vocational/icon1.png"),
          title: "想跳槽，没底气",
          content:
            "长期在小型软件公司或外包公司工作，技术视野较窄，提升较慢，缺少一线互联网项目经验",
        },
        {
          img: require("../../assets/vocational/icon2.png"),
          title: "项目经验零碎",
          content:
            "工作内容多是增删改查的简单开发，经历的项目用户访问量较小，未经历完整的大型项目开发",
        },
        {
          img: require("../../assets/vocational/icon3.png"),
          title: "自学困难没人带",
          content:
            "无法真正接触到大型互联网项目的开发和架构技术、高并发分布式技术、求学无门",
        },
        {
          img: require("../../assets/vocational/icon4.png"),
          title: "长期无突破",
          content:
            "工作年限较长，但几乎是把第一年的工作重复做了N年，技术水平停滞不前，缺少核心竞争力",
        },
        {
          img: require("../../assets/vocational/icon5.png"),
          title: "技术基础薄弱",
          content:
            "自身基础较差，缺少系统的学习经验，技术更新迭代快，和大厂越行越远",
        },
      ],
      upgradeList: [
        {
          img: require("../../assets/vocational/icon6.png"),
          title: "想跳槽没底气",
          content:
            "长期在小型软件公司或外包公司工作，技术视野较窄，提升较慢，缺少一线互联网项目经验",
        },
        {
          img: require("../../assets/vocational/icon7.png"),
          title: "想跳槽没底气",
          content:
            "长期在小型软件公司或外包公司工作，技术视野较窄，提升较慢，缺少一线互联网项目经验",
        },
        {
          img: require("../../assets/vocational/icon8.png"),
          title: "想跳槽没底气",
          content:
            "长期在小型软件公司或外包公司工作，技术视野较窄，提升较慢，缺少一线互联网项目经验",
        },
        {
          img: require("../../assets/vocational/icon9.png"),
          title: "想跳槽没底气",
          content:
            "长期在小型软件公司或外包公司工作，技术视野较窄，提升较慢，缺少一线互联网项目经验",
        },
        {
          img: require("../../assets/vocational/icon10.png"),
          title: "想跳槽没底气",
          content:
            "长期在小型软件公司或外包公司工作，技术视野较窄，提升较慢，缺少一线互联网项目经验",
        },
      ],
    };
  },
};
</script>
<style scoped>
.IntroInfo {
  /* height: 100%;
  width: 100%; */
  /* background: red; */
  width: 696px;
  height: 257px;
  /* padding: 20px; */
  margin: 0 auto;
  /* box-shadow: 0px 0px 9px 3px rgba(204, 221, 244, 0.5); */
  /* border-radius: 20px; */
  /* font-size: 24px; */
font-family: SourceHanSansCN-Bold, SourceHanSansCN;
font-weight: 400;
color: #404040;
line-height: 36px;
text-align: justify;
}
/* .Intro {
  margin-top: 40px;
  margin-bottom: 80px;
  width: 68.7%;
  background: #ffffff;
} */
/* .Intro > p {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  margin: 60px 0;
} */
.problems,
.upgrades {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  /* padding: 0 20px; */
}
.problem {
  width: 10%;
  min-width: 140px;
  text-align: center;
  margin: 0 20px;
}
.problem > img {
  width: 40%;
  min-width: 50px;
}
.problem > p:nth-of-type(1),
.upgrade > p:nth-of-type(1) {
  font-size: 18px;
  font-weight: bold;
  opacity: 0.8;
  /* min-width: 130px; */
}
.problem > p:nth-of-type(2),
.upgrade > p:nth-of-type(2) {
  font-size: 14px;
  text-align: justify;
  opacity: 0.6;
}
.upgrade {
  width: 8%;
  min-width: 120px;
  text-align: center;
  box-shadow: 0 0 20px rgb(65, 95, 207, 0.3);
  margin: 40px 5px;
  padding: 15px;
}
.upgrade:nth-child(odd) {
  transform: translate(0, -30px);
}
.upgrade:nth-child(even) {
  transform: translate(0, 30px);
}
.upgrade > img {
  width: 40%;
  min-width: 50px;
}
</style>